import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [randomMessages, setRandomMessages] = useState({
        comment1: '',
        comment2: '',
        comment3: '',
        comment4: ''
    });

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            path: '/socket.io',
            transports: ['websocket', 'polling'],
            secure: process.env.NODE_ENV === 'production'
        });
        
        setSocket(newSocket);

        // Cleanup on unmount
        return () => newSocket.close();
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('connect', () => {
            console.log('Connected to socket server');
        });

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        socket.on('initialMessages', (messages) => {
            setMessages(messages);
        });

        socket.on('message', (message) => {
            setMessages(prev => [...prev, { text: message }]);
        });

        socket.on('randomMessages', (messages) => {
            if (messages.length >= 4) {
                setRandomMessages({
                    comment1: messages[0],
                    comment2: messages[1],
                    comment3: messages[2],
                    comment4: messages[3]
                });
            }
        });

        return () => {
            socket.off('initialMessages');
            socket.off('message');
            socket.off('randomMessages');
            socket.off('connect');
            socket.off('connect_error');
        };
    }, [socket]);

    const value = {
        socket,
        messages,
        randomMessages
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};