import React, { useEffect, useRef } from 'react';
import Comments from '../Comments/Comments';
import styles from "../../../src/styles/components/Hero.module.css"
import BookGlow from "../../assets/images/book-glow.png"
import HeroImg from "../../assets/images/hero-img.png"
import { useSocket } from '../../conyext/SocketContext';

const Hero = () => {
  const { messages } = useSocket();
  const momentsRef = useRef(null);

  useEffect(() => {
    const startTyping = () => {
      const spans = document.querySelectorAll(`.${styles.typewriter} span`);
      let delay = 0;
      const totalDelay = spans.length * 500 + 2000;

      const animateSpans = () => {
        spans.forEach(span => {
          setTimeout(() => {
            span.style.opacity = '1';
          }, delay);
          delay += 500;
        });

        setTimeout(() => {
          spans.forEach(span => {
            span.style.opacity = '0';
          });
          delay = 0;
          animateSpans();
        }, totalDelay);
      };

      animateSpans();
    };

    startTyping();
  }, []);

  useEffect(() => {
    if (momentsRef.current) {
      momentsRef.current.scrollTop = momentsRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <section className={styles.hero}>
      <img 
        className={styles.glow} 
        src={BookGlow}
        alt="glow effect" 
      />
      <img 
        className={styles.heroImg} 
        src={HeroImg}
        alt="hero" 
      />

      <Comments />

      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.typewriter}>
            <span><h2>The Book of</h2></span>
            <span><h1>HOLY SHIT</h1></span>
            <span>
              <p>Record Your Best Shit Moments With Us In The Book Of Holy Shit!</p>
            </span>
          </div>
        </div>

        <div className={styles.right}>
          <p className={styles.heading}>
            Read our community's hilarious Holy Shit Moments
          </p>
          <div className={styles.moments} ref={momentsRef}>
            {messages.map((message, index) => (
              <li key={index}>Holy Shit, {message.text}</li>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.redBanner}>
        <p>
          We will reward the top 3 Holy Shit Moments with BOHS tokens on a daily basis.
          <br />
          If you want to participate, provide your Telegram or Twitter (X) ID below:
        </p>
      </div>
    </section>
  );
};

export default Hero;