import React, { useState } from 'react';
import styles from "../../../src/styles/components/Form.module.css";
import { useSocket } from '../../conyext/SocketContext';

const Form = () => {
  const { socket } = useSocket();
  const [formData, setFormData] = useState({
    telegramId: '',
    twitterId: '',
    age: '',
    message: 'Holy Shit, '
  });

  const ageGroups = ['Teenager', '20-35', '36-50', '50+'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'message' && !value.toLowerCase().startsWith('holy shit, ')) {
      setFormData(prev => ({
        ...prev,
        [name]: 'Holy Shit, ' + value.replace(/^holy shit,\s*/i, '')
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleMessageFocus = (e) => {
    const input = e.target;
    if (!input.value.startsWith('Holy Shit, ')) {
      setFormData(prev => ({
        ...prev,
        message: 'Holy Shit, '
      }));
      setTimeout(() => {
        input.setSelectionRange(input.value.length, input.value.length);
      }, 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let messageText = formData.message.trim();
    
    if (messageText.toLowerCase() === 'holy shit,') {
      messageText = '';
    } else if (messageText.toLowerCase().startsWith('holy shit, ')) {
      messageText = messageText.substring(11).trim();
    }

    if (messageText && socket) {
      socket.emit('sendMessage', {
        messageText,
        telegramId: formData.telegramId,
        twitterId: formData.twitterId,
        age: formData.age
      });

      // Reset form
      setFormData({
        telegramId: '',
        twitterId: '',
        age: '',
        message: 'Holy Shit, '
      });
    }
  };

  return (
    <section className={styles.form}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.input1}>
            <div className={styles.redLabel}>
              Provide your Telegram ID
            </div>
            <input
              type="text"
              name="telegramId"
              value={formData.telegramId}
              onChange={handleInputChange}
              placeholder="Enter Telegram ID"
            />
          </div>

          <p className={styles.orText}>OR</p>

          <div className={styles.input1}>
            <div className={styles.redLabel}>
              Provide your X ID
            </div>
            <input
              type="text"
              name="twitterId"
              value={formData.twitterId}
              onChange={handleInputChange}
              placeholder="Enter X (Twitter) ID"
            />
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.redLabel}>
            Please choose your age bracket.
          </div>
          <div className={styles.boxContainer}>
            {ageGroups.map((group) => (
              <div key={group} className={styles.box}>
                <input
                  type="radio"
                  name="age"
                  value={group}
                  checked={formData.age === group}
                  onChange={handleInputChange}
                  className={styles.radioInput}
                  id={`age-${group}`}
                />
                <li>{group}</li>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.redLabel}>
          Tell us your Holy Shit moment (Max 100 Character text)
        </div>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            onFocus={handleMessageFocus}
            maxLength={111} // 100 + "Holy Shit, " length
            className={styles.messageInput}
            placeholder="Holy Shit, "
          />
          <button 
            className={styles.submitBtn}
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
};

export default Form;