import React from 'react';
import styles from "../../../src/styles/components/Comments.module.css"
import { useSocket } from '../../conyext/SocketContext';

const Comments = () => {
  const { randomMessages } = useSocket();

  const comments = [
    {
      id: 1,
      className: styles.comment1,
      heading: "1st ranked holy shit",
      text: randomMessages.comment1 || "Holy Shit, I walked into the wrong restroom."
    },
    {
      id: 2,
      className: styles.comment2,
      heading: "2nd ranked holy shit",
      text: randomMessages.comment2 || "Holy Shit, I tripped on absolutely nothing in a crowded place."
    },
    {
      id: 3,
      className: styles.comment3,
      heading: "3rd ranked holy shit",
      text: randomMessages.comment3 || "Holy Shit, I laughed out loud at my own joke before I could tell it."
    },
    {
      id: 4,
      className: styles.comment4,
      heading: "Random holy shit",
      text: randomMessages.comment4 || "Holy Shit, I forgot to attach the file to the 'here's the file' email"
    }
  ];

  return (
    <>
      {comments.map(comment => (
        <div key={comment.id} className={comment.className}>
          <div className={styles.commentHeading}>
            {comment.heading}
          </div>
          <p>Holy Shit, {comment.text}</p>
        </div>
      ))}
    </>
  );
};

export default Comments;