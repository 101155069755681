import React from 'react';
import Hero from './components/Hero/Hero';
import Form from './components/Form/Form';
import TokenEconomics from './components/TokenEconomics/TokenEconomics';
import Telegram from "./assets/images/telegram.png"
import Twitter from "./assets/images/twitter.png"
import { SocketProvider } from './conyext/SocketContext';

function App() {
  return (
    <SocketProvider>
      <div className="app">
        <nav>
          <a href="#" className="btn">Buy BOHS</a>
          <a href="#" className="btn1">COINGECKO</a>
          <a href="#" className="btn1">DEXTOOLS</a>
          <div className="links">
            <a href="#"><img src={Telegram} alt="telegram" /></a>
            <a href="#"><img src={Twitter} alt="twitter" /></a>
          </div>
        </nav>
        <Hero />
        <Form />
      </div>
    </SocketProvider>
  );
}

export default App;